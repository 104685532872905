<template>
  <div class="wish-box" :class="!isApp ? 'appTop' : ''">
    <!-- 头部下拉菜单 -->
    <div class="headerBox">
      <van-dropdown-menu class="dropRow" active-color="#3BDBE8">
        <van-dropdown-item v-model="value1" :options="optionMenu" @change="selectMenu" />
        <div class="logs positDot" @click="toWishRecord">
          达成记录
          <div class="dot" v-if="wishPoint"></div>
        </div>
      </van-dropdown-menu>
    </div>

    <!-- 列表 -->
    <van-list
      v-if="list.length"
      class="vanList"
      v-model:loading="loading"
      :finished="finished"
      @load="onLoad"
      @offset="100"
    >
      <div class="wrapper">
        <div v-for="(item, index) in list" :key="index" class="">
          <div class="wish-item-box">
            <!-- 置灰 -->
            <div class="grayBox" v-if="item.status !== 1"></div>
            <div class="wish-item-header">
              <span
                >您于{{ getTime(item.created_time) }}许下愿望，想{{
                  item.wish_type == 1 ? '要' : '送'
                }}：</span
              >
            </div>
            <div class="wish-item-content">
              <div class="item-info">
                <div class="item-img-name">
                  <div class="itemImg">
                    <img
                      class="imgThumb"
                      :src="item.platform == 1 ? item.image_thumb : item.yihua_image"
                      alt=""
                      srcset=""
                    />
                    <img
                      v-if="levelsChange(item.levels) && item.config_type === 1"
                      class="levelIcon"
                      :src="levelsChange(item.levels)"
                      alt=""
                    />
                  </div>
                  <div class="" style="width: 100%">
                    <div class="content-box">
                      <div class="name">
                        <!--  -->
                        <p class="title">
                          {{ item.config_name }}（{{ item.success_num }}/{{ item.wish_num }}）
                        </p>
                        <!-- item.status !== 2 &&
                            item.status !== 3 && -->
                        <p class="desc">
                          {{ item.platform == 1 ? '看漫' : '一画' }}数藏 - 《{{
                            item.display_name
                          }}》
                        </p>
                      </div>
                      <!-- origin_price -->
                      <div class="item-price">
                        <div>
                          <span>¥{{ item.unit_price / 100 }}</span
                          >/份
                        </div>
                        <div style="margin-top: 4px">感谢费</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-bt">
                <div class="">
                  <div class="item-button" v-if="item.status == 1">
                    <button type="button" @click="editWish(item)">修改</button>
                    <button type="button" @click="withdrawPopShow(item)">撤回</button>
                    <!-- <button type="button" v-if="item.wish_type == 2" @click="shareShow(item)">
                      分享
                    </button> -->
                    <!-- <button type="button" v-if="item.platform == 1" @click="shareShow(item)">
                      分享
                    </button> -->
                  </div>
                  <div class="item-time" v-if="item.status == 2">
                    {{ getTime(item.success_time) }}愿望已达成
                  </div>
                  <div class="item-time" v-if="item.status == 3 || item.status == 4">
                    {{ getTime(item.cancel_time) }}愿望已撤回
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>

    <div v-if="!list.length" style="padding-bottom: 45px">
      <img
        style="width: 100%"
        src="https://activity-resource.321mh.com/nft/notice/xuyuanchi_activity.png"
        alt=""
      />
    </div>

    <!-- 底部按钮 -->
    <div class="footer">
      <div class="footerBtn" @click="wishShow">许个愿吧</div>
      <div class="market" @click="toMarket">
        市场行情<img class="iconRight" src="../imgs/point/icon-right.png" />
      </div>
    </div>

    <!-- 发布愿望弹层 -->
    <wishSheet ref="wishSheet" @success="refreshList" />

    <!-- 撤回愿望 -->
    <popup
      v-model:show.sync="withdrawPop"
      title="撤回愿望"
      :desc="'确定撤回' + config_name + '的' + (wish_type == 1 ? '求赠' : '赠送') + '愿望吗？'"
      :btncon="{ cancel: '取消', success: '确定' }"
      @confirm="withdraw"
      @cancel="withdrawPop = false"
      :closeable="false"
    />

    <!-- 分享 -->
    <wishShare ref="wishShare" />
    <PageLoading :show="loadingShow" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref, inject } from 'vue';
import popup from '@/components/popup/index.vue';
import wishSheet from '@/components/wishPublish.vue';
import wishShare from '@/components/wishShare.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getWishList, cancelwish, yihuacancelwish } from '@/service/wish';
import { getwhiteuser } from '@/service/user';
import moment from 'moment';
import { nftUtils } from '@/utils';
import PageLoading from '@/components/loading/PageLoading.vue';

export default {
  name: 'wishWell',
  components: {
    popup,
    wishSheet,
    wishShare,
    PageLoading
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toastFn = inject('$toast');
    const wishSheet = ref('wishSheet');
    const wishShare = ref('wishShare');
    const state = reactive({
      isApp: nftUtils.isInApp(),
      wishPoint: false,
      tabType: 0,
      finished: false,
      loading: true,
      loadingShow: true,
      list: [],
      page: 1,
      value1: 0,
      optionMenu: [
        { text: '全部愿望', value: 0 },
        { text: '赠送愿望', value: 2 },
        { text: '获得愿望', value: 1 }
      ],
      publishWishShow: false, // 发布愿望许愿弹窗

      wishType: [
        { text: '求赠藏品', value: 1 },
        { text: '赠送藏品', value: 2 }
      ],

      formData: {
        type: 1,
        num: 1,
        price: 1
      },

      withdrawPop: false, //撤回弹窗

      wish_id: null,
      config_name: '',
      wish_type: 1,
      wishWhite: false,
      platformType: null
    });

    onMounted(async () => {
      onLoad();
      await store.dispatch('getisshowredmark');
      state.wishPoint = store.state.wishPoint;

      getwhiteuser({}).then(res => {
        console.log(res);
        state.loadingShow = false;
        if (res.status == 0) {
          state.wishWhite = res.data;
          if (!state.wishWhite) {
            router.push({ path: '/home' });
          }
        }
      });
    });

    // 加载列表
    const onLoad = () => {
      // 当加载还没结束时页码加一
      if (!state.finished && state.list.length) {
        state.page = state.page + 1;
      }
      getList();
    };

    // 请求列表
    const getList = () => {
      getWishList({
        page: state.page,
        page_size: 10,
        wish_type: state.tabType
      })
        .then(res => {
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          state.server_time = res.server_time;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
          if (res.data && res.data.length < 10) {
            state.finished = true;
          }
          // if (state.page >= data.totalPage) state.finished = true;
        })
        .catch(err => {
          state.loading = false;
          state.finished = true;
        });
    };

    const shareShow = row => {
      wishShare.value.showSheetMeth(row);
    };

    const wishShow = () => {
      wishSheet.value.showSheet();
    };

    // 修改愿望
    const editWish = row => {
      wishSheet.value.editSheet(row);
    };

    // 列表菜单过滤
    const selectMenu = val => {
      console.log(val);
      state.tabType = val;
      refreshList();
    };

    const refreshList = () => {
      console.log('134234');
      state.page = 1;
      state.list = [];
      state.finished = false;
      state.loading = true;
      onLoad();
    };

    // 二次确认撤回弹窗
    const withdrawPopShow = item => {
      state.config_name = item.config_name;
      state.wish_type = item.wish_type;
      state.withdrawPop = true;
      state.wish_id = item.wish_id;
      state.platformType = item.platform;
    };

    // 撤回愿望
    const withdraw = () => {
      if (state.platformType == 1) {
        cancelwish({
          wish_id: state.wish_id
        }).then(res => {
          console.log(res);
          if (res.status == 0 && res.data) {
            state.withdrawPop = false;

            state.page = 1;
            state.list = [];
            state.finished = false;
            state.loading = true;
            onLoad();
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.withdrawPop = false;
            state.page = 1;
            state.list = [];
            state.finished = false;
            state.loading = true;
            onLoad();
          }
        });
      } else {
        yihuacancelwish({
          wish_id: state.wish_id
        }).then(res => {
          console.log(res);
          if (res.status == 0 && res.data) {
            state.withdrawPop = false;

            state.page = 1;
            state.list = [];
            state.finished = false;
            state.loading = true;
            onLoad();
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.withdrawPop = false;
            state.page = 1;
            state.list = [];
            state.finished = false;
            state.loading = true;
            onLoad();
          }
        });
      }
    };

    // 跳转达成记录页面
    const toWishRecord = () => {
      router.push({ path: '/wishRecord' });
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const toMarket = () => {
      location.href = 'https://market.genscmeta.com/2022/NFTmarket';
    };

    return {
      ...toRefs(state),
      onLoad,
      selectMenu,
      withdraw,
      toWishRecord,
      wishShow,
      shareShow,
      getTime,
      editWish,
      withdrawPopShow,
      levelsChange,
      refreshList,
      wishSheet,
      wishShare,

      toMarket
    };
  }
};
</script>

<style lang="scss">
.wish-box {
  .headerBox {
    .van-dropdown-menu__bar {
      width: 80px;
      border-radius: 8px;
    }
    .van-dropdown-menu__title {
      padding: 0;
      padding-right: 6px;
    }
    .van-popup--top {
      width: 97px;
      top: 8px;
      left: 14px;
      background: #1c172a;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #97949f;
    }
    .van-cell {
      background: #1c172a;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
    }
    .van-dropdown-item__option--active,
    .van-dropdown-item__option--active .van-dropdown-item__icon {
      font-size: 14px;
    }
    .van-cell__value {
      display: none;
    }
    .addnum .van-stepper__input {
      margin: 0px;
      background: #1c172a;

      .van-stepper__minus--disabled,
      .van-stepper__plus--disabled {
        opacity: 0.3;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.positDot {
  position: relative;
  .dot {
    width: 6px;
    height: 6px;
    background: #e63f3f;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    left: 100%;
    top: 0px;
  }
}
* {
  touch-action: pan-y;
}
.appTop {
  padding-top: 50px;
}
.wish-box {
  .headerBox {
    position: absolute;
    width: 100%;
    padding-top: 17px;

    .dropRow {
      padding: 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      --van-dropdown-menu-title-text-color: #ffffff;
      --van-dropdown-menu-title-font-size: 12px;
      --van-dropdown-menu-height: 28px;
      --van-dropdown-menu-title-line-height: 28px;
      --van-dropdown-menu-background-color: #2f293f;
      --van-dropdown-item-z-index: 20;
      .logs {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .vanList {
    padding: 45px 0;
  }

  .wish-item-box {
    margin: 12px 16px;
    background-color: $primaryc;
    border-radius: 8px 8px 8px 8px;
    position: relative;

    .grayBox {
      border-radius: 8px 8px 8px 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #1c172a;
      opacity: 0.5;
      z-index: 10;
    }

    .wish-item-header {
      padding: 0 14px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #494359;
    }

    .item-info {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: 20px 14px 0 14px;

      .content-box {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
      }

      .item-img-name {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        p {
          margin: 0;
        }

        .itemImg {
          font-size: 0;

          position: relative;

          .imgThumb {
            width: 70px;
            height: 70px;
            border-radius: 4px 4px 4px 4px;
            object-fit: cover;
          }

          .levelIcon {
            width: 43px;
            height: auto;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 3px;
          }
        }

        .name {
          margin-left: 12px;

          .title {
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            overflow: hidden;

            text-overflow: ellipsis;

            display: -webkit-box;

            -webkit-box-orient: vertical;

            -webkit-line-clamp: 2;
          }

          .desc {
            width: 180px;
            font-size: 12px;
            margin-top: 7px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }

      .item-price {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        text-align: right;

        span {
          font-size: 16px;
          font-weight: 500;
          color: #3bdbe8;
        }
      }
    }

    .item-bt {
      display: flex;
      justify-content: flex-end;
      padding-right: 14px;
      align-items: center;
      height: 48px;
      border-top: 1px solid #494359;
      margin-top: 20px;
      font-size: 14px;
      .heji {
        text-align: end;
        span {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .item-time {
        font-size: 10px;
        text-align: right;
      }
      .item-button {
        button {
          border: none;
          width: 60px;
          height: 28px;
          border-radius: 4px 4px 4px 4px;
          background: transparent;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          border: 1px solid rgba(255, 255, 255, 0.5);
          margin-left: 8px;
        }
      }
    }
  }

  .dataNullBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 150px;
      height: 110px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 15;
    text-align: center;
    transform: translateX(-50%);
    padding-bottom: 100px;
    padding-top: 20px;
    background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);

    .footerBtn {
      width: 252px;
      height: 44px;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px 4px 4px 4px;
      font-size: 20px;
      font-weight: bold;
      color: #1c172a;
      line-height: 44px;
      margin: 0 auto;
    }

    .market {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-top: 12px;
      font-weight: 500;
      color: #ffffff;
    }

    .iconRight {
      width: 16px;
    }
  }
}
</style>
