<template>
  <div @click="closeSel">
    <!-- 发布愿望 -->
    <van-action-sheet
      v-model:show="show"
      :round="false"
      style="background-color: #1c172a"
      class="wishSheet"
    >
      <div class="header">
        <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="show = false" />
        <span class="type">发布愿望</span>
      </div>
      <div class="block"></div>

      <!-- 内容 -->
      <div class="form">
        <div class="formItem">
          <div class="label">愿望类型</div>
          <div class="formContent" v-if="!formData.wish_id">
            <span
              class="wishRadio"
              v-for="item in wishType"
              :key="item.value"
              @click="wishTypeSel(item.value)"
            >
              <van-icon
                class="icon"
                :name="formData.wish_type == item.value ? coll_choose : coll_no_choose"
              />

              <span class="text">{{ item.text }}</span>
            </span>
          </div>

          <div class="formContent disformContent" v-if="formData.wish_id">
            <span class="wishRadio diswishRadio" v-for="item in wishType" :key="item.value">
              <van-icon
                class="icon"
                :name="formData.wish_type == item.value ? disChoose : disNoChoose"
              />

              <span class="text">{{ item.text }}</span>
            </span>
          </div>
        </div>
        <div class="formItem">
          <div class="label">藏品所属平台</div>
          <div class="">
            <div class="formContent" v-if="!formData.wish_id">
              <span
                class="wishRadio"
                v-for="item in wishTypePlat"
                :key="item.value"
                @click="platformSel(item.value)"
              >
                <van-icon
                  class="icon"
                  :name="platformType == item.value ? coll_choose : coll_no_choose"
                />

                <span class="text text2">{{ item.text }}</span>
              </span>
            </div>

            <div class="formContent disformContent" v-if="formData.wish_id">
              <span class="wishRadio diswishRadio" v-for="item in wishTypePlat" :key="item.value">
                <van-icon
                  class="icon"
                  :name="platformType == item.value ? disChoose : disNoChoose"
                />

                <span class="text">{{ item.text }}</span>
              </span>
            </div>

            <p class="tips2" v-if="platformType == 2">注：求赠成功后，请前往一画App查看藏品</p>
          </div>
        </div>

        <div class="formItem" v-if="platformType == 1">
          <div class="label">选择藏品</div>
          <div class="formContent selectCollect">
            <div class="selectBox">
              <div
                style="width: 100%"
                @click.stop="
                  () => {
                    if (formData.wish_type == 2 && !configListSend) {
                      seriesSel = false;
                      collectSel = false;
                    } else {
                      seriesSel = !seriesSel;
                      collectSel = false;
                    }
                  }
                "
              >
                <span>{{ configName }}</span>
                <van-icon class="iconSel" name="play" />
              </div>

              <div class="selMenu" v-if="seriesSel">
                <div
                  v-for="(val, key, i) in formData.wish_type == 1 ? configListGet : configListSend"
                  :key="i"
                  @click="selectConfig(key, val)"
                  :class="key == configName ? 'activeMenu' : ''"
                >
                  {{ key }}
                </div>
              </div>
            </div>
            <div class="selectBox">
              <div
                style="width: 100%"
                @click.stop="
                  () => {
                    collectSel = !collectSel;
                    seriesSel = false;
                  }
                "
              >
                <span>{{ collectName }}</span>
                <van-icon class="iconSel" name="play" />
              </div>

              <div class="selMenu" v-if="collectSel && collectList.length">
                <div
                  v-for="(item, index) in collectList"
                  :key="index"
                  @click="selectCollect(item)"
                  :class="item.config_detail_id == formData.config_detail_id ? 'activeMenu' : ''"
                >
                  <img class="levelIcon" :src="levelsChange(item.levels)" alt="" srcset="" />
                  {{ item.config_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 一画的选择藏品列表和看漫的数据结构不一样 -->
        <div class="formItem" v-if="platformType == 2">
          <div class="label">选择藏品</div>
          <div class="formContent selectCollect">
            <div class="selectBox">
              <div
                style="width: 100%"
                @click.stop="
                  () => {
                    if (formData.wish_type == 2 && !yhConfigListSend) {
                      seriesSel = false;
                      collectSel = false;
                    } else {
                      seriesSel = !seriesSel;
                      collectSel = false;
                    }
                  }
                "
              >
                <span>{{ yhConfigName }}</span>
                <van-icon class="iconSel" name="play" />
              </div>

              <div class="selMenu" v-if="seriesSel">
                <div
                  v-for="(item, index) in formData.wish_type == 1
                    ? yhConfigListGet
                    : yhConfigListSend"
                  :key="index"
                  @click="selectyhConfig(item)"
                  :class="item.display_name == yhConfigName ? 'activeMenu' : ''"
                >
                  {{ item.display_name }}
                </div>
              </div>
            </div>
            <div class="selectBox">
              <div
                style="width: 100%"
                @click.stop="
                  () => {
                    collectSel = !collectSel;
                    seriesSel = false;
                  }
                "
              >
                <span>{{ yhCollectName }}</span>
                <van-icon class="iconSel" name="play" />
              </div>

              <div class="selMenu" v-if="collectSel && yhCollectList.length">
                <div
                  v-for="(item, index) in yhCollectList"
                  :key="index"
                  @click="selectyhCollect(item)"
                  :class="item.id == formData.config_detail_id ? 'activeMenu' : ''"
                >
                  <!-- <img class="levelIcon" :src="levelsChange(item.levels)" alt="" srcset="" /> -->
                  {{ item.config_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="formItem">
          <div class="label">{{ formData.wish_type == 1 ? '求赠数量' : '赠送数量' }}</div>
          <div class="formContent addnum">
            <van-stepper
              :integer="true"
              v-model="formData.wish_num"
              :max="formData.wish_type == 1 ? 99999 : has_num"
              :min="1"
              class="stepper"
              input-width="60px"
              @change="steeperChange"
            />
            <span v-if="formData.wish_type == 2" class="hasNum">共 {{ has_num }} 份可赠送</span>
          </div>
        </div>
        <div class="formItem">
          <div class="label">感谢费</div>
          <div class="">
            <div class="formContent priceItem">
              <van-field
                class="priceInput"
                v-model="formData.unit_price_fake"
                :border="false"
                type="number"
                placeholder="输入价格"
                :maxlength="8"
                @blur="textBlur"
              />
              <span>元/份</span>
              <span class="sum">合计：{{ formData.unit_price_fake * formData.wish_num }}元</span>
            </div>
            <!-- <div class="tipPrice">*未成功求赠的商品，感谢费将退回余额</div> -->
          </div>
        </div>
      </div>

      <!-- 立即许愿按钮 -->
      <!-- 我的藏品【转赠】愿望类型【赠送藏品】两个按钮需判断当前用户注册时长是否大于3天，如果未大于，则展示倒计时，点击时toast提示：“新用户注册后3天后才可转赠藏品”
            时间大于24小时按钮显示：x天x小时后可转赠/许愿
            时间小于24小时按钮显示：x小时xx分钟xx秒后可转赠/许愿 -->

      <div class="formSubmit" v-if="formData.wish_type == 2 && diffHours() < 72" @click="countTime">
        <van-count-down
          class="countDown"
          millisecond
          :format="
            diffTime() < 24 * 60 * 60 * 1000 ? 'HH小时mm分钟ss秒后可许愿' : 'DD天HH小时后可许愿'
          "
          :time="diffTime()"
          @finish="finistTime"
        />
      </div>
      <div class="formSubmit" v-else @click="tipConfirm">立即许愿</div>

      <div class="tips" v-if="formData.wish_type == 2">
        *若愿望达成，您的钱包账户将收到以上感谢费（平台服务费5%, 推广期间免费）
      </div>
      <div class="agreeTips" v-if="formData.wish_type == 1">
        <span @click="agreeFlag = !agreeFlag">
          <van-icon class="icon" size="0.427rem" :name="agreeFlag ? coll_choose : coll_no_choose" />

          <span class="text">愿望达成时，允许从钱包中自动扣除感谢费</span>
        </span>
      </div>
    </van-action-sheet>

    <!-- 重要提醒 -->
    <van-popup
      class="vantPop"
      v-model:show="tipPop"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup">
        <div class="content">
          <div class="title">重要提醒</div>
          <div class="desc">
            {{
              formData.wish_type == 2
                ? '您设置的感谢费太高，愿望可能较难达成，是否继续提交？'
                : '您设置的感谢费太低，愿望可能较难达成，是否继续提交？'
            }}
          </div>
        </div>
        <div class="btn">
          <div @click="closeTipPop">调整</div>
          <div @click="continueSubmit">继续提交</div>
        </div>
      </div>
    </van-popup>

    <van-popup
      class="vantPop"
      v-model:show="pointTip"
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup">
        <div class="content">
          <div class="title">积分领取提示</div>
          <div class="desc">有修炼积分待领取，继续转赠将放弃未领取的积分，请前往领取</div>
        </div>
        <div class="btn">
          <div @click="nogetPoint">放弃领取</div>
          <div @click="toLearning">去领积分</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import {
  getcollections,
  compareprice,
  makewish,
  setmakewish,
  getmakewishcollection,
  yihuamakewish,
  setyihuamakewish
} from '@/service/wish';
import { sandpayCloud } from '@/service/user';
import moment from 'moment';
import { useStore } from 'vuex';
import { localStorageUtil, nftUtils } from '@/utils';
import { Toast } from 'vant';
import popup from '@/components/popup/index.vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  emits: ['success'],
  props: {},
  components: {
    popup
  },

  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const store = useStore();

    const state = reactive({
      pointTip: false,
      show: false,
      server_time: null,
      created_time: null,
      wishType: [
        { text: '求赠藏品', value: 1 },
        { text: '赠送藏品', value: 2 }
      ],
      wishTypePlat: [
        { text: '看漫', value: 1 },
        { text: '一画', value: 2 }
      ],

      formData: {
        wish_type: 2,
        wish_num: 1,
        unit_price: 1,
        total_price: null,
        config_detail_id: null
      },
      seriesSel: false, // 下拉选择藏品系列下拉框
      collectSel: false,

      configName: '',

      collectName: '',

      configListGet: [], // 求赠藏品下拉列表
      configListSend: [], // 增出藏品列表
      collectList: [],
      tipPop: false, //  重要提醒弹窗

      has_num: 0, // 可选的最大值

      clickFlag: true,
      agreeFlag: false,

      platformType: 1, // 1看漫、2一画
      yhConfigListGet: [], // 求赠藏品下拉列表
      yhConfigListSend: [], // 增出藏品列表
      yhCollectList: [],

      yhConfigName: '',
      yhCollectName: ''
    });

    const continueSubmit = () => {
      if (state.platformType == 1) {
        confirmWish();
      } else {
        confirmWishYh();
      }
    };

    const platformSel = val => {
      console.log(val);
      state.platformType = val;

      state.formData.config_detail_id = null;
      state.configName = '';
      state.collectName = '';
      state.collectList = [];
      state.yhConfigName = '';
      state.yhCollectName = '';
      state.yhCollectList = [];

      state.agreeFlag = false;
    };

    // 不领积分直接许愿
    const nogetPoint = () => {
      confirmWish(1);
    };

    const toLearning = () => {
      state.pointTip = false;
      router.push({ path: '/Learning/retreat' });
    };

    const reset = () => {
      getconfig(null);
      getyhconfig();
      state.formData = {
        wish_id: null,
        wish_type: 2,
        wish_num: 1,
        unit_price: null,
        unit_price_fake: null,
        total_price: null,
        config_detail_id: null
      };
      state.configName = '';
      state.collectName = '';
      state.collectList = [];
      state.yhConfigName = '';
      state.yhCollectName = '';
      state.yhCollectList = [];

      state.agreeFlag = false;

      state.platformType = 1;
    };

    // 获取下拉框数据（选择藏品）
    const getconfig = (wish_id = null) => {
      getcollections({
        wish_type: 1,
        wish_id: wish_id
      }).then(res => {
        if (res.status == 0) {
          state.configListGet = res.data;
          // if (state.configList.length) state.configName = state.configList[0];
          console.log(res.data);
        }
      });

      getcollections({
        wish_type: 2,
        wish_id: wish_id
      }).then(res => {
        if (res.status == 0) {
          state.configListSend = res.data;
          // if (state.configList.length) state.configName = state.configList[0];
          console.log('333', res.data);
        }
      });
    };

    // 获取下拉框数据（选择藏品）
    const getyhconfig = () => {
      getmakewishcollection({
        wish_type: 1
      }).then(res => {
        if (res.status == 0) {
          state.yhConfigListGet = res.data;
          // if (state.configList.length) state.configName = state.configList[0];
          console.log(res.data);
        }
      });

      getmakewishcollection({
        wish_type: 2
      }).then(res => {
        if (res.status == 0) {
          state.yhConfigListSend = res.data;
          // if (state.configList.length) state.configName = state.configList[0];
          console.log('333', res.data);
        }
      });
    };

    const showSheet = () => {
      state.show = true;
      reset();
    };

    // 修改赋值
    const editSheet = row => {
      console.log(row);
      state.show = true;
      state.agreeFlag = false;
      state.platformType = row.platform;

      state.formData = {
        wish_id: row.wish_id,
        wish_type: row.wish_type,
        wish_num: row.wish_num,
        unit_price: row.unit_price / 100,
        unit_price_fake: row.unit_price / 100,
        total_price: row.total_price,
        config_detail_id: row.config_detail_id
      };

      // state.collectList =
      //   row.wish_type == 1
      //     ? state.configListGet[row.display_name]
      //     : state.configListSend[row.display_name];

      if (state.platformType == 1) {
        state.configName = row.display_name;
        state.collectName = row.config_name;
        getcollections({
          wish_type: 1,
          wish_id: row.wish_id
        }).then(res => {
          if (res.status == 0) {
            state.configListGet = res.data;
            if (row.wish_type == 1) {
              state.collectList = state.configListGet[row.display_name];
            }
            // if (state.configList.length) state.configName = state.configList[0];
            console.log(res.data);
          }
        });

        getcollections({
          wish_type: 2,
          wish_id: row.wish_id
        }).then(res => {
          if (res.status == 0) {
            state.configListSend = res.data;

            // if (state.configList.length) state.configName = state.configList[0];
            console.log(res.data);
            // 修改的时候需要遍历取出下拉框中的最大值
            if (row.wish_type == 2) {
              state.collectList = state.configListSend[row.display_name];
              for (let i in state.collectList) {
                if (state.collectList[i].config_detail_id == row.config_detail_id) {
                  state.has_num = state.collectList[i].has_num;
                  state.formData.wish_num = state.collectList[i].has_num;
                }
              }
            }
          }
        });
      } else if (state.platformType == 2) {
        state.yhConfigName = row.display_name;
        state.yhCollectName = row.config_name;
        getmakewishcollection({
          wish_type: 1,
          wish_id: row.wish_id
        }).then(res => {
          if (res.status == 0) {
            state.yhConfigListGet = res.data;
            if (row.wish_type == 1) {
              for (let i in state.yhConfigListGet) {
                if (state.yhConfigListGet[i].id == row.config_id) {
                  state.yhCollectList = state.yhConfigListGet[i].child_items;
                }
              }
            }
            // if (state.configList.length) state.configName = state.configList[0];
            console.log(res.data);
          }
        });

        getmakewishcollection({
          wish_type: 2,
          wish_id: row.wish_id
        }).then(res => {
          if (res.status == 0) {
            state.yhConfigListSend = res.data;
            // if (state.configList.length) state.configName = state.configList[0];
            console.log('333', res.data);

            if (row.wish_type == 2) {
              for (let i in state.yhConfigListSend) {
                if (state.yhConfigListSend[i].id == row.config_id) {
                  state.yhCollectList = state.yhConfigListSend[i].child_items;
                }
              }
              // state.collectList = state.configListSend[row.display_name];
              for (let i in state.yhCollectList) {
                if (state.yhCollectList[i].id == row.config_detail_id) {
                  state.has_num = state.yhCollectList[i].has_num;
                  state.formData.wish_num = state.yhCollectList[i].has_num;
                }
              }
            }
          }
        });
      }

      // state.has_num = row.has_num;
    };

    // 选择许愿类型
    const wishTypeSel = val => {
      state.formData.wish_type = val;
      //清空藏品id和name
      state.formData.config_detail_id = null;
      state.collectName = '';
      state.configName = '';
      state.collectList = [];

      state.yhCollectName = '';
      state.yhConfigName = '';
      state.yhCollectList = [];
      state.has_num = 0;
      closeSel();
    };

    //
    const selectConfig = (key, val) => {
      //赋值系列名 id，和藏品下拉列表
      state.configName = key;
      state.collectList = val;
      //清空藏品id和name
      state.formData.config_detail_id = null;
      state.collectName = '';
      state.seriesSel = false;
    };

    // 选择藏品
    const selectCollect = item => {
      // 赋值藏品name id
      state.formData.config_detail_id = item.config_detail_id;
      state.collectName = item.config_name;
      state.collectSel = false;
      state.has_num = item.has_num;
    };

    // 一画选择系列
    const selectyhConfig = item => {
      state.yhConfigName = item.display_name;
      state.yhCollectList = item.child_items;
      //清空藏品id和name
      state.formData.config_detail_id = null;
      state.yhCollectName = '';
      state.seriesSel = false;
    };

    // 一画选择藏品
    const selectyhCollect = item => {
      state.formData.config_detail_id = item.id;
      state.yhCollectName = item.config_name;
      state.collectSel = false;
      state.has_num = item.has_num;
    };

    // 价格限制
    const inputPriceRule = (value, decimal) => {
      console.log(value, decimal);
      if (!value) {
        return value;
      }
      if (value == '-') {
        return null;
      }
      //限制小数位
      if (decimal > 0) {
        let regex = new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`, 'g');
        value = (value && value.match(regex)[0]) || null;
        console.log(value);
      }
      return value;
    };

    // 校验必填项
    const verifyForm = () => {
      if (!state.formData.config_detail_id) {
        return toastFn({ message: '请选择藏品', duration: 3000 });
      } else if (!state.formData.wish_num) {
        return toastFn({ message: '请选择数量', duration: 3000 });
      } else if (!state.formData.unit_price_fake) {
        return toastFn({ message: '请输入感谢费', duration: 3000 });
      } else if (state.formData.unit_price_fake < 1)
        return toastFn({ message: '感谢费最少1元哦~', duration: 3000 });
      return true;
    };

    // 点击立即许愿嫌比对价格
    const tipConfirm = () => {
      if (verifyForm()) {
        if (state.formData.wish_type == 1 && !state.agreeFlag) {
          toastFn({ message: '需同意自动扣除感谢费', duration: 3000 });
          return;
        }
        if (state.platformType == 2) {
          toastFn({ message: '暂不支持一画', duration: 3000 });
          return;
        }
        console.log(state.formData, state.platformType);
        compareprice({
          config_detail_id: state.formData.config_detail_id,
          wish_type: state.formData.wish_type,
          unit_price: (Number(state.formData.unit_price_fake) * 100).toFixed(0)
        }).then(res => {
          if (res.status == 0 && res.data) {
            state.tipPop = true;
          } else {
            if (state.platformType == 1) {
              confirmWish();
            } else {
              confirmWishYh();
            }
          }
        });
      }
    };

    // 比对价格后点否立即许愿
    const confirmWish = (confirm_type = null) => {
      state.formData.confirm_type = confirm_type;
      state.formData.unit_price = (Number(state.formData.unit_price_fake) * 100).toFixed(0);
      state.formData.total_price = (
        state.formData.unit_price_fake *
        state.formData.wish_num *
        100
      ).toFixed(0);
      // delete state.formData.unit_price_fake;
      // 节流限制

      if (!state.clickFlag) return;

      if (!state.formData.wish_id) {
        state.clickFlag = false;
        // 添加许愿
        makewish(state.formData).then(res => {
          console.log(res);

          state.tipPop = false;

          if (res.status == 0) {
            state.show = false;
            state.pointTip = false;
            Toast.loading({
              message: '许愿中',
              forbidClick: true,
              loadingType: 'spinner',
              overlay: true,
              duration: 3000
            });
            setTimeout(() => {
              state.clickFlag = true;
              ctx.emit('success');
              Toast.clear();
            }, 3000);
          } else if (res.status == 421 || res.status == 1) {
            // 没有开通钱包账号要跳去开通
            // toastFn({ message: res.message, duration: 3000 });
            sandpayCloud({
              page_type: res.status == 1 ? 2 : null
            }).then(resIn => {
              console.log(resIn);
              if (resIn.status == 0) {
                window.location.href = resIn.data.url;
              }
            });
          } else if (res.status == 599) {
            state.pointTip = true;
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.show = false;
            setTimeout(() => {
              ctx.emit('success');
            }, 1500);
          }
        });
      } else {
        // 修改许愿
        state.clickFlag = false;
        setmakewish(state.formData).then(res => {
          Toast.clear();
          console.log(res);

          state.tipPop = false;
          if (res.status == 0) {
            state.show = false;
            state.pointTip = false;
            Toast.loading({
              message: '许愿中',
              forbidClick: true,
              loadingType: 'spinner',
              overlay: true,
              duration: 3000
            });
            setTimeout(() => {
              state.clickFlag = true;
              ctx.emit('success');
              Toast.clear();
            }, 3000);
          } else if (res.status == 421 || res.status == 1) {
            // 没有开通钱包账号要跳去开通
            // toastFn({ message: res.message, duration: 3000 });
            sandpayCloud({
              page_type: res.status == 1 ? 2 : null
            }).then(resIn => {
              console.log(resIn);
              if (resIn.status == 0) {
                window.location.href = resIn.data.url;
              }
            });
          } else if (res.status == 599) {
            state.pointTip = true;
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.show = false;
            setTimeout(() => {
              ctx.emit('success');
            }, 1500);
          }
        });
      }
    };

    const confirmWishYh = (confirm_type = null) => {
      state.formData.confirm_type = confirm_type;
      state.formData.unit_price = (Number(state.formData.unit_price_fake) * 100).toFixed(0);
      state.formData.total_price = (
        state.formData.unit_price_fake *
        state.formData.wish_num *
        100
      ).toFixed(0);
      // delete state.formData.unit_price_fake;
      // 节流限制

      if (!state.clickFlag) return;

      if (!state.formData.wish_id) {
        state.clickFlag = false;
        // 添加许愿
        yihuamakewish(state.formData).then(res => {
          console.log(res);

          state.tipPop = false;

          if (res.status == 0) {
            state.show = false;
            state.pointTip = false;
            Toast.loading({
              message: '许愿中',
              forbidClick: true,
              loadingType: 'spinner',
              overlay: true,
              duration: 3000
            });
            setTimeout(() => {
              state.clickFlag = true;
              ctx.emit('success');
              Toast.clear();
            }, 3000);
          } else if (res.status == 421 || res.status == 1) {
            // 没有开通钱包账号要跳去开通
            // toastFn({ message: res.message, duration: 3000 });
            sandpayCloud({
              page_type: res.status == 1 ? 2 : null
            }).then(resIn => {
              console.log(resIn);
              if (resIn.status == 0) {
                window.location.href = resIn.data.url;
              }
            });
          } else if (res.status == 599) {
            state.pointTip = true;
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.show = false;
            setTimeout(() => {
              ctx.emit('success');
            }, 1500);
          }
        });
      } else {
        // 修改许愿
        state.clickFlag = false;
        setyihuamakewish(state.formData).then(res => {
          Toast.clear();
          console.log(res);

          state.tipPop = false;
          if (res.status == 0) {
            state.show = false;
            state.pointTip = false;
            Toast.loading({
              message: '许愿中',
              forbidClick: true,
              loadingType: 'spinner',
              overlay: true,
              duration: 3000
            });
            setTimeout(() => {
              state.clickFlag = true;
              ctx.emit('success');
              Toast.clear();
            }, 3000);
          } else if (res.status == 421 || res.status == 1) {
            // 没有开通钱包账号要跳去开通
            // toastFn({ message: res.message, duration: 3000 });
            sandpayCloud({
              page_type: res.status == 1 ? 2 : null
            }).then(resIn => {
              console.log(resIn);
              if (resIn.status == 0) {
                window.location.href = resIn.data.url;
              }
            });
          } else if (res.status == 599) {
            state.pointTip = true;
          } else {
            toastFn({ message: res.message, duration: 3000 });
            state.show = false;
            setTimeout(() => {
              ctx.emit('success');
            }, 1500);
          }
        });
      }
    };

    const closeSel = () => {
      state.seriesSel = false;
      state.collectSel = false;
    };

    const closeTipPop = () => {
      state.tipPop = false;
    };

    // 对比时间是否超过多少小时
    const diffHours = () => {
      return moment(state.server_time).diff(
        moment(Number(moment(state.created_time).format('x'))),
        'hours'
      );
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(
        moment(moment(state.created_time).add(3, 'd').format('YYYY-MM-DD HH:mm:ss'))
      ).diff(state.server_time, 'millisecond');
    };

    const countTime = () => {
      toastFn({ message: '新用户注册后3天后才可转赠藏品', duration: 3000 });
    };

    const finistTime = async () => {
      await store.dispatch('refreshLoginInfo');
      state.created_time = store.state.loginInfo.created_time;
      state.server_time = Number(localStorageUtil.get('server_time'));
    };

    onMounted(async () => {
      if (nftUtils.isLogin()) {
        await store.dispatch('refreshLoginInfo');
        state.created_time = store.state.loginInfo.created_time;
        // state.created_time = '2022-09-19T08:10:01.000Z';
        state.server_time = Number(localStorageUtil.get('server_time'));
        console.log(
          'state.server_time',
          moment(state.created_time)
            .add(+3, 'd')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }
    });

    const steeperChange = val => {
      if (val > 99999) {
        state.formData.wish_num = 99999;
      }
    };

    const textBlur = val => {
      console.log(typeof val.target.value);
      // let noluck = 100 - this.probability
      //   this.lotteryForm.prizeList[0].probability = noluck.toFixed(2)

      let unit_price_fake = val.target.value;
      let regex = new RegExp(`^\\d+(?:\\.\\d{0,${1}})?`, 'g');
      unit_price_fake = (unit_price_fake && unit_price_fake.match(regex)[0]) || null;
      if (state.formData.unit_price_fake > 99999) unit_price_fake = 99999;
      state.formData.unit_price_fake = unit_price_fake;
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      showSheet,
      wishTypeSel,
      selectConfig,
      selectCollect,
      confirmWish,
      tipConfirm,
      editSheet,
      closeSel,
      closeTipPop,
      inputPriceRule,
      diffHours,
      diffTime,
      countTime,
      finistTime,
      textBlur,
      steeperChange,
      toLearning,
      nogetPoint,
      levelsChange,
      platformSel,
      selectyhConfig,
      selectyhCollect,
      continueSubmit,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png'),
      coll_no_choose: require('../imgs/icon/coll_no_choose.png'),
      coll_choose: require('../imgs/icon/coll_choose.png'),
      disNoChoose: require('../imgs/icon/disNoChoose.png'),
      disChoose: require('../imgs/icon/disChoose.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';

.wishSheet {
  overflow: hidden;
  .countDown {
    display: inline-block;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .type {
      // line-height: 16px;
      font-size: 18px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }

  .block {
    height: 60px;
    background-color: $bc;
  }

  .stepper {
    --van-stepper-background-color: #494359;
    --van-stepper-button-disabled-color: rgba(73, 67, 89, 0.5);
    --van-stepper-button-disabled-icon-color: rgba(255, 255, 255, 0.2);
    --van-stepper-button-icon-color: #fff;
    --van-stepper-input-text-color: #fff;
    --van-stepper-input-disabled-background-color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    // height: 26px;
    overflow: hidden;
    font-size: 0px;
  }

  .form {
    padding: 0 20px;
    .formItem {
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 25px;

      .label {
        width: 90px;
        text-align: right;
      }

      .formContent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 8px;
      }

      .tipPrice {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #ea4444;
        line-height: 22px;
      }

      .addnum {
        .hasNum {
          margin-left: 8px;
          font-size: 12px;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.7);
          line-height: 18px;
        }
      }
    }

    .priceItem {
      display: flex;
      align-items: center;
      .van-cell {
        width: 78px;
        height: 26px;
        line-height: 26px;
        margin-right: 8px;
        padding: 0 5px;
        color: white;
        background: #1c172a;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.7);
      }
      .priceInput {
        --van-field-placeholder-text-color: rgba(255, 255, 255, 0.3);
      }
      ::v-deep .van-field__control {
        color: #fff;
      }

      span {
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
      }
      .sum {
        margin-left: 15px;
      }
    }

    .wishRadio {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .text {
        margin-left: 2px;
      }
      .text2 {
        width: 56px;
      }
    }
    .tips2 {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      margin-left: 54px;
      margin: 0px;
      margin-top: 12px;
      padding-left: 8px;
    }
    .diswishRadio {
      .text {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .selectCollect {
      .selectBox {
        position: relative;
        display: flex;
        align-items: center;
        width: 95px;
        height: 26px;
        line-height: 26px;
        background: #1c172a;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.7);
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 22px;

        span {
          width: 100%;
          line-height: 26px;
          display: inline-block;
          vertical-align: bottom;
          padding: 0 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }

        .iconSel {
          position: absolute;
          right: 6px;
          top: 50%;
          font-size: 13px;
          transform: translateY(-50%) rotate(90deg);
        }

        .selMenu {
          overflow-y: scroll;
          position: absolute;
          z-index: 16;
          top: calc(100% + 3px);
          left: 0;
          width: 158px;
          max-height: 163px;
          background: #1c172a;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #c1bfc5;

          > div {
            display: flex;
            align-items: center;
            line-height: 36px;
            padding: 0 8px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            border-bottom: 1px solid #4d426b;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }

          .activeMenu {
            font-size: 14px;
            font-weight: 400;
            color: #3cdbe8;
          }

          .levelIcon {
            width: 29px;
          }
        }
      }
    }

    .icon {
      font-size: 22px;
    }
  }

  .formSubmit {
    width: 335px;
    height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #1c172a;
    line-height: 44px;
    margin: 0 auto;
  }

  .tips {
    padding: 14px 20px 24px 20px;
    font-size: 12px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    line-height: 18px;
  }

  .agreeTips {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px 24px 20px;
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    > span {
      display: inline-flex;
    }
    .text {
      margin-left: 6px;
    }
  }
}

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 20px 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #494359;

    div {
      flex: 1;
      margin: 15px 0;
    }

    div:nth-child(2) {
      border-left: 1px solid #494359;
    }
  }
}
</style>
<style>
.vantPop {
  background: transparent !important;
}
</style>
