<template>
  <van-action-sheet v-model:show="showSheet" class="sheet" :round="false">
    <div class="share-wrapper">
      <div class="header">
        <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />

        <span class="type">分享</span>
      </div>
      <div class="share-content">
        <div class="share-item" @click="qrcodeDom">
          <img :src="icon_pic" alt="" />
          <p>生成分享海报</p>
        </div>
        <div class="share-item" @click="openCopytextPop">
          <img :src="icon_text" alt="" />
          <p>生成分享文本</p>
        </div>
      </div>
      <div class="btn" @click="close">取消</div>
    </div>
  </van-action-sheet>
  <div class="" id="share-wish">
    <!-- 复制文本弹窗 -->
    <van-popup class="vantPop" v-model:show="copytextPop">
      <div class="popup">
        <div class="copytextPop">
          <div class="title">文本已复制</div>
          <div class="tip">打开第三方软件粘贴给好友</div>
          <div class="copyContent">
            <div>{{ shareText }}</div>

            <div style="color: #3cdbe8; font-weight: 500" @click="copyText">复制文本</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 海报 -->
    <!-- <div style="position: fixed; bottom: 200%; font-size: 0">
      <div id="testPost">
        <img class="posterBg" :src="posterBg + '?v=06282'" />
        <vueQr
          class="qrcodeImgStyle"
          :text="qrcodeImg"
          :margin="0"
          :logoSrc="'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1654699036635.jpg'"
        >
        </vueQr>
      </div>
    </div> -->

    <div v-if="shareInfo" style="position: fixed; top: -200%; z-index: 9999">
      <div id="shareContainer">
        <img
          class="shareBg"
          src="https://activity-resource.321mh.com/nft/notice/xuyuan/red_bg.png"
          alt=""
        />
        <div class="info">
          <div class="itemImg">
            <img class="imgThumb" :src="shareInfo.image_icon" alt="" srcset="" />
            <img
              v-if="levelsChange(shareInfo.levels) && shareInfo.config_type === 1"
              class="levelIcon"
              :src="levelsChange(shareInfo.levels)"
              alt=""
            />
          </div>
          <div class="name">
            {{ shareInfo.config_name }} <span class="nameNumIcon"> x </span>
            <span class="nameNum">{{ shareInfo.wish_num - shareInfo.success_num }}</span>
          </div>
          <div style="white-space: nowrap">看漫数藏 -《{{ shareInfo.display_name }}》</div>
        </div>
        <div class="shareDesc">
          <img
            v-if="shareInfo.wish_type == 1"
            src="https://activity-resource.321mh.com/nft/notice/xuyuan/collection.png"
            alt=""
            srcset=""
          />
          <img v-else src="https://activity-resource.321mh.com/nft/notice/xuyuan/give.png" alt="" />
        </div>

        <div class="shareQrCode">
          <vueQr class="vueQrCode" :text="qrcodeImg" :margin="0"> </vueQr>
        </div>
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="posterPop">
      <div class="poster">
        <!-- <van-image width="100" height="100" :src="posterImg" /> -->
        <img style="pointer-events: auto !important" :src="posterImg" alt="" srcset="" />

        <div v-if="posterImg" class="saveTip">长按图片保存</div>
      </div>
    </van-popup>

    <PageLoading :show="pageLoading" />
  </div>
</template>

<script>
import { reactive, toRefs, inject, onMounted } from 'vue';
import { nftUtils } from '@/utils';
import { useRouter } from 'vue-router';
import useClipboard from 'vue-clipboard3';
import PageLoading from '@/components/loading/PageLoading.vue';
import { useStore } from 'vuex';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import domtoimage from 'dom-to-image';
import upload from '@/utils/upload.js';
export default {
  emits: ['close'],
  components: {
    PageLoading,
    vueQr
  },
  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const store = useStore();
    const { toClipboard } = useClipboard();
    const state = reactive({
      isLogin: nftUtils.isLogin(),
      copytextPop: false, // 复制文本弹窗
      shareText: '',
      showSheet: false,
      myInviteCode: 'F3YD',
      pageLoading: false,
      posterBg: require('../imgs/poster/posterUser.jpg'),
      qrcodeImg: window.location.origin + '/wishSharePage', // 二维码图片
      posterPop: false, // 弹窗显示
      posterImg: '', // 海报图片

      shareInfo: null
    });
    onMounted(async () => {
      if (state.isLogin) {
        await store.dispatch('refreshLoginInfo');
        console.log(store);
        if (store.state.loginInfo) {
          state.myInviteCode = store.state.loginInfo.my_code;
        }
      }
    });
    const close = () => {
      state.showSheet = false;
    };
    const showSheetMeth = row => {
      state.showSheet = true;
      state.shareInfo = row;
      console.log('shareInfo', state.shareInfo);
      setPosterImg();
    };

    // 拼接二维码链接
    const setPosterImg = () => {
      let url = window.location;
      if (!window.location.origin) {
        url =
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');
      } else {
        url = window.location.origin;
      }

      if (url.indexOf('mh51') > -1) {
        url = 'https://collect.yx03.com';
      }
      state.qrcodeImg = url + '/wishSharePage?shareId=' + state.shareInfo.share_id;
      console.log('state.qrcodeImg', state.qrcodeImg);
    };
    // 生成海报
    const qrcodeDom = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      state.showSheet = false;
      state.pageLoading = true;
      nftUtils.setcnzzRecordByBtn('生成专属邀请海报');

      // 没有上传时重新上传
      uploadDomToPoster(nftUtils.isIOS());
    };
    // 直接转为Blob生成并上传的oss，iOS特殊处理，需要执行两次toBlob
    const uploadDomToPoster = isIos => {
      if (isIos) {
        domtoimage
          .toBlob(document.getElementById('shareContainer'), { quality: 1 })
          .then(dataUrl => {
            updateDomToPosterOnece();
          });
      } else {
        updateDomToPosterOnece();
      }
    };
    // 直接转为toBlob，首次进入时两次生成并上传oss显示，第二次及以后，只需一次生成并上传oss显示
    const updateDomToPosterOnece = () => {
      domtoimage.toBlob(document.getElementById('shareContainer'), { quality: 1 }).then(dataUrl => {
        if (nftUtils.isSupprotNftShare()) {
          upload.uploadHandle64Common(dataUrl, function (link) {
            nftUtils.nftSharePic(link);
            state.pageLoading = false;
            store.state.commonPosterUrl = link;
          });
        } else {
          upload.uploadHandle64Common(dataUrl, function (link) {
            console.log(link);
            state.posterImg = link;
            state.posterPop = true;
            state.pageLoading = false;
            store.state.commonPosterUrl = link;
            console.log(link);
          });
        }
      });
    };

    const havePosterUrlToPoster = () => {
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftSharePic(store.state.commonPosterUrl);
        state.pageLoading = false;
      } else {
        state.posterImg = store.state.commonPosterUrl;
        state.posterPop = true;
        state.pageLoading = false;
      }
    };
    const openCopytextPop = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      const shareUrl =
        window.location.origin + '/wishSharePage?shareId=' + state.shareInfo.share_id;
      if (state.shareInfo.wish_type == 1) {
        state.shareText =
          '我的愿望是收藏' +
          (state.shareInfo.wish_num - state.shareInfo.success_num) +
          '份《' +
          state.shareInfo.display_name +
          '》' +
          state.shareInfo.config_name +
          '，请点击链接进入看漫数藏，帮我达成愿望吧！' +
          shareUrl;
      } else {
        state.shareText =
          '我有' +
          (state.shareInfo.wish_num - state.shareInfo.success_num) +
          '份《' +
          state.shareInfo.display_name +
          '》' +
          state.shareInfo.config_name +
          '想赠送给你，请点击链接进入看漫数藏，立即签收吧！' +
          shareUrl;
      }

      console.log(nftUtils.isSupprotNftShare());
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftShareText(state.shareText);
      } else {
        state.copytextPop = true;
        try {
          await toClipboard(state.shareText);
        } catch (e) {
          console.log(e);
        }
      }
    };

    // 复制邀请链接
    const copyText = async () => {
      nftUtils.setcnzzRecordByBtn('复制文本');
      try {
        await toClipboard(state.shareText);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      close,
      qrcodeDom,
      openCopytextPop,
      copyText,
      showSheetMeth,
      setPosterImg,
      levelsChange,
      icon_pic: require('@/imgs/wish/shareIconPic.png'),
      icon_text: require('@/imgs/wish/shareIconText.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';

#shareContainer {
  position: relative;
  .shareBg {
    width: 562px;
    // width: 281px;
  }

  .info {
    position: absolute;
    top: 28px;
    // top: 14px;
    font-size: 30px;
    // font-size: 15px;
    left: 50%;
    transform: translateX(-50%);

    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }

  .itemImg {
    font-size: 0;
    position: relative;

    .imgThumb {
      width: 274px;
      // width: 137px;
    }

    .levelIcon {
      width: 144px;
      // width: 72px;
      height: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 6px;
      // bottom: 3px;
    }
  }

  .name {
    line-height: 44px;
    // line-height: 30px;
  }

  .nameNumIcon {
    font-size: 24px;
    // font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  .nameNum {
    font-size: 32px;
    // font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
  }

  .shareDesc {
    position: absolute;
    top: 484px;
    // top: 242px;
    left: 50%;
    transform: translateX(-50%);

    img {
      // width: 255px;
      // height: 93px;
      width: 510px;
      height: 186px;
    }
  }

  .shareQrCode {
    position: absolute;
    top: 716px;
    // top: 358px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 220px;
    border-radius: 4px;
    border: 6px solid rgba(255, 233, 203, 1);
    // width: 110px;
    // height: 110px;
    // border-radius: 2px;
    // border: 3px solid rgba(255, 233, 203, 1);
    overflow: hidden;

    .vueQrCode {
      width: 100%;
    }
  }
}

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 30px 16px;
    line-height: 26px;
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.copytextPop {
  padding: 24px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .tip {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.7);
  }

  .copyContent {
    overflow: hidden;
    margin-top: 16px;
    background: #1c172a;
    border-radius: 4px 4px 4px 4px;
    padding: 12px;

    line-height: 22px;
  }
}
.btn {
  height: 44px;
  width: 250px;
  font-weight: 500;
  // background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  border-radius: 4px 4px 4px 4px;
  margin: 0 auto;
}
.share-content {
  margin: 38px 20px 24px 20px;
  display: flex;
  justify-content: space-between;

  .share-item {
    width: 50%;
    text-align: center;
    font-size: 0px;
    img {
      width: 36px;
    }
    p {
      margin-top: 6px;
      font-size: 14px;
    }
  }
}
.share-wrapper {
  // position: fixed;
  // top: 0;
  padding-bottom: 36px;
  background-color: $bc;
  color: #fff;
}
.header {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;

  padding-top: 3px;

  .img-zs {
    height: 16px;
  }
  .type {
    // line-height: 16px;
    // margin: 16px 0 24px 20px;
    margin-top: 2px;
    font-size: 18px;
    font-weight: 500;
    color: $fc;
    text-align: center;
  }
}

#testPost {
  position: relative;
  display: inline-block;
  .posterBg {
    width: 616px;
    height: 1044px;
  }

  .qrcodeImgStyle {
    width: 120px;
    height: 120px;
    // width: 60px;
    // height: 60px;
    position: absolute;
    left: 71px;
    bottom: 100px;
    // left: 26px;
    // bottom: 44px;
    // transform: translateX(-50%);
    // top: 352px;
    border: 4px solid;
    border-radius: 4px;
    border-image: linear-gradient(180deg, rgba(203, 255, 227, 1), rgba(112, 204, 217, 1)) 4 4;
  }
}

.poster {
  width: 308px;

  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 100%;
  }
}
</style>
<style lang="scss">
#share-wish {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }

  .shareBtn,
  .posterBtn {
    text-align: center;
    width: 105px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 36px;
    color: #1c172a;
  }

  .qrcodeImg {
    top: 292px;
  }
}
</style>
